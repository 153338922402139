import api from "../../../services/api.service"


export const gasModule = {
    namespaced: true,
    state: {
        data: {types:[],categories:[]},
    },
    getters: {
        canReadRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canRead']('real_estate');
        },
        canEditRealEstate(state, getters, rootState, rootGetters) {
            return rootGetters['user/canEdit']('real_estate');
        },
        getAllCategories(state) {
            if (state.data == null || state.data.categories == null)
                return [];
            return state.data.categories;
        },

        getAllTypes(state) {
            if (state.data == null || state.data.types == null)
                return [];
            return state.data.types;
        },

        allGasStatus() {
            return [
                { value: "active", text: ('Active') },
                { value: "inactive", text: ('Inactive') },
                { value: "disturbance", text: ('Disturbance') },
                { value: "in progress", text: ('In progress') }
            ];
        },



    },
    mutations: {
        updateData(state, data) {
            let stateData = {};
            stateData.loaded = true;
            stateData.categories = data["Categories"];
            stateData.types = data["Types"];
            state.data = stateData;
        }
    },
    actions: {

        copySuccess(actions,id){
            actions.dispatch('notifications/success',
                {title: 'Success', message: 'Gas  ID '+id+' copied'},
                {root: true});

        },
        deleteService(actions,{id}){
            return new Promise((resolve, reject) => {

                api.sendRequest('gas/delete/'+id, {}).then(
                    (ans) => {

                        if (ans["Deleted"] == null)
                            return reject('Could not delete service item ');

                        resolve(ans["Deleted"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        getAllGass(actions,{ page, amount, filter,sortcolumn, sortby }) {

            return new Promise(function (resolve, reject) {

                    api.sendRequest('gas/get', {
                        buids: actions.rootGetters['data/getSelectedBranchesIds'].join(","),
                        page,
                        amount,
                        sortcolumn,
                        sortby
                    },filter).then(
                        (ans) => {
                            if (ans["Gass"] == null)
                                return reject('Could not get Gass ');

                            resolve(ans);
                        }, (error) => {
                            reject(error.message)
                        })
                })

        },
        updateDocumentFile(action, {id, file}) {
            return new Promise(function (resolve, reject) {
                api.sendRequest('gas/update/file/' + id, null, file).then(
                    (ans) => {

                        action.dispatch('notifications/success',
                            {
                                title: {text: 'file %s', arguments: ['status']},
                                message: {text: '%s updated successfully', arguments: ['file']}
                            },
                            {root: true});
                        resolve(ans["editableFields"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        getGasById(actions,id){
            return new Promise((resolve, reject) => {

                api.sendRequest('gas/id/'+id, {}).then(
                    (ans) => {

                        if (ans["Gas"] == null)
                            return reject('Could not get gas ');

                        resolve(ans["Gas"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },

        updateGas(actions,{gas}){
            return new Promise((resolve, reject) => {

                api.sendRequest('gas/update',null, gas).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Update gas', message: 'Gas updated successfully'},
                            {root: true});

                        if (ans["RowsUpdated"] == null)
                            return reject('Could not update gas ');

                        resolve(ans["RowsUpdated"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        sendEmail(actions,gas){
            return new Promise((resolve, reject) => {

                api.sendRequest('gas/sendEmail',null, gas).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Email Support', message: 'Email was sent successfully'},
                            {root: true});

                        if (ans["GasId"] == null)
                            return reject('Could not sent email');

                        resolve(ans["GasId"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        },
        addGas(actions,{gas}){
            return new Promise((resolve, reject) => {
                api.sendRequest('gas/create', null,gas).then(
                    (ans) => {
                        actions.dispatch('notifications/success',
                            {title: 'Add gas', message: 'Gas created successfully'},
                            {root: true});

                        if (ans["Gas"] == null)
                            return reject('Could not create gas ');

                        console.log("gas answer created: ",ans)
                        resolve(ans["Gas"]);
                    }, (error) => {
                        reject(error.message)
                    })
            })
        }
    },
};
